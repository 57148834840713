import React from "react";

import { useTranslation } from "react-i18next";

function AboutUs() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden mt-[80px] xl:mt-[500px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between relative">
                <div className="flex flex-col xl:items-end">
                    <p className="pepsi text-[#34F3F3] text-[45px] xl:text-[125px] leading-[1]">{t('main.6')}</p>
                    <p className="pepsi text-[#39ABFE] text-[25px] xl:text-[55px] leading-[1]">{t('main.7')}</p>
                </div>
                <div className="max-w-[525px] mt-[20px]">
                    <p className="font-[300] roboto text-white text-[14px] xl:text-[17px]">{t('main.8')}</p>
                    <p className="font-[300] roboto text-white text-[14px] xl:text-[17px] mt-[10px]">{t('main.9')}</p>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;

import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import AboutUs from "../components/Home/AboutUs";
import OurAdvantages from "../components/Home/OurAdvantages";
import WhatDo from "../components/Home/WhatDo";
import OurServices from "../components/Home/OurServices";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <AboutUs/>
            <OurAdvantages/>
            <WhatDo/>
            <OurServices/>
        </div>
    );
}

export default Home;

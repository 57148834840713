import React from 'react';

import { useTranslation, Trans } from "react-i18next";

import logo from '../img/logo_purple.png'

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='headerbg z-10 relative max-w-[1280px] mx-[20px] xl:mx-auto xl:h-[319px] mt-[80px] xl:mt-[200p] flex flex-col p-[30px] xl:p-[50px] justify-between'>
      <div className='flex flex-col xl:flex-row gap-[20px] xl:gap-[50px]'>
        <div>
          <img src={logo} alt="" />
        </div>
        <div className='flex flex-col gap-[10px] xl:gap-[20px]'>
          <p className='open-sans text-[#313131] text-[15px] xl:text-[17px]'>{t('footer.1')}</p>
          <p className='open-sans text-[#313131] text-[15px] xl:text-[17px]'><Trans>{t('footer.2')}</Trans></p>
        </div>
        <div className='flex flex-col gap-[10px] xl:gap-[20px]'>
          <p className='open-sans text-[#313131] text-[15px] xl:text-[17px]'>{t('footer.3')}</p>
          <p className='open-sans text-[#313131] text-[15px] xl:text-[17px]'><Trans>{t('footer.4')}</Trans></p>
        </div>
        <div className='flex flex-col gap-[10px] xl:gap-[20px]'>
          <p className='open-sans text-[#313131] text-[15px] xl:text-[17px]'>{t('footer.c')}</p>
          <p className='open-sans text-[#313131] text-[15px] xl:text-[17px]'>{t('footer.cc')}</p>
        </div>
        <div className='flex flex-col gap-[10px] xl:gap-[20px]'>
          <p className='open-sans text-[#313131] text-[15px] xl:text-[17px]'>{t('footer.5')}</p>
          <p className='open-sans text-[#313131] text-[15px] xl:text-[17px]'>{t('footer.6')}</p>
        </div>
      </div>
      <p className='ml-auto mt-[40px] xl:mt-0 open-sans text-[#313131] text-[14px] xl:text-[17px]'>{t('footer.7')}</p>
    </div>
  );
}

export default Footer;
import React from "react";

import { useTranslation } from "react-i18next";

import head from '../../img/Home/head.png'

import Fade from "react-reveal/Fade";

function WhatDo() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col-reverse xl:flex-row justify-between relative">
                <div className="xl:max-w-[314px] mt-[40px] xl:mt-[360px]">
                    <p className="text-[#FFFFFFB2] open-sans text-[14px] xl:text-[17px]">{t('main.24')}</p>
                    <p className="text-[#FFFFFFB2] open-sans text-[14px] xl:text-[17px] mt-[15px]">{t('main.25')}</p>
                    <div className="buttonNew mt-[20px] xl:mt-[50px] flex mx-auto xl:mx-0 xl:ml-auto cursor-pointer">
                        <p className="open-sans text-[17px] font-[700] leading-[1.4]">{t('main.5')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <rect x="0.731445" y="0.530151" width="20.9397" height="20.9397" rx="10.4698" fill="black"/>
                            <path d="M5.09375 11H17.3086" stroke="white" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.2012 4.8927L17.3086 11.0001L11.2012 17.1075" stroke="white" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div  className="mt-[40px] xl:mt-0 xl:absolute xl:w-[687.434px] xl:h-[642px] xl:left-1/2 xl:-translate-x-1/2">
                    <Fade bottom>
                        <img src={head} alt=""/>
                    </Fade>
                </div>
               
                <svg className="hidden xl:block absolute top-0 right-0" xmlns="http://www.w3.org/2000/svg" width="682" height="745" viewBox="0 0 682 745" fill="none">
                    <g filter="url(#filter0_f_12_8917)">
                        <circle cx="392.129" cy="353.129" r="110.5" transform="rotate(137.442 392.129 353.129)" fill="#25FFE5"/>
                    </g>
                    <defs>
                        <filter id="filter0_f_12_8917" x="0.62793" y="-38.3719" width="783.001" height="783.001" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="140.5" result="effect1_foregroundBlur_12_8917"/>
                        </filter>
                    </defs>
                </svg>
                <div className="flex flex-col items-end xl:mt-[160px]">
                    <p className="pepsi text-[#34F3F3] text-[45px] xl:text-[125px] leading-[1]">{t('main.22')}</p>
                    <p className="pepsi text-[#39ABFE] text-[25px] xl:text-[55px] leading-[1.5]">{t('main.23')}</p>
                </div>
            </div>
        </div>
    );
}

export default WhatDo;

import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { images } from "./imagesToLoad";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import Services from "./pages/Services";

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [init, setInit] = useState(false);

    const loadImage = (image) => {
        return new Promise((resolve, reject) => {
            const newImage = new Image();
            newImage.src = image;
            newImage.onload = () => {
                resolve(image);
            };
            newImage.onerror = (err) => reject(err);
        });
    };

    useEffect(() => {
        if (isLoading) {
            $('body').addClass("ovf-hidden");
        }
        Promise.all(images.map((image) => loadImage(image)))
            .then(() => setIsLoading(false))
            .catch((err) => {
                console.log("Failed to load images", err);
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isLoading) {
            $('body').removeClass("ovf-hidden");
            $('#loader').addClass("hide");
        }
    }, [isLoading]);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    return (
        <div className="App">
            {init && (
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: "#000000",  // Черный фон
                            },
                        },
                        fpsLimit: 1200,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: false,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 2,
                                },
                                repulse: {
                                    distance: 100,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#ffffff",  // Белые кружочки
                            },
                            links: {
                                enable: true,  // Убираем линии между частицами
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outModes: {
                                    default: "out",  // Частицы выходят за пределы экрана и появляются с другой стороны
                                },
                                random: true,
                                speed: 1,  // Медленное движение частиц
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800,
                                },
                                value: 100,  // Количество частиц
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: { min: 1, max: 7 },  // Маленькие кружочки
                            },
                        },
                        detectRetina: true,
                    }}
                />
            )}
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                </Routes>
                <Footer />
            </Router>
        </div>
    );
};

export default App;
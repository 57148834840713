import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";

import logo from "../img/logo.png";

function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <div>
            <div className="relative z-[999] w-[30px] h-[25px] cursor-pointer flex flex-col justify-between xl:hidden" onClick={toggleMenu}>
                <div className={`w-full h-[1px] transition duration-300 transform ${isOpen ? 'rotate-45 translate-y-[9px] bg-[#fff] w-[35px]' : 'bg-black'}`}></div>
                <div className={`w-full h-[1px] bg-black transition duration-300 transform ${isOpen ? 'hidden' : 'block'}`}></div>
                <div className={`w-full h-[1px]  transition duration-300 transform ${isOpen ? '-rotate-45 -translate-y-[15px] bg-[#fff] w-[35px]' : 'bg-black'}`}></div>
            </div>
            <div className={`h-screen top-[-20px] right-0 bottom-0 w-full md:w-1/2 bg-black z-50 absolute transform transition-all duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="flex flex-col items-center mt-[50px] gap-[30px]">
                    <img src={logo} alt="" className="w-[40px] invert"/>
                    <Link to="/"><p className={`text-[18px] text-white inter ${location.pathname === '/' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.1')}</p></Link>
                    <Link to="/"><p className={`text-[18px] text-white inter ${location.pathname === '/' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.2')}</p></Link>
                    <div className="button cursor-pointer !border-white">
                        <p className="inter text-white text-[16px]">{t('header.3')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;
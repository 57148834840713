import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const data = [
    {
        title: 'services.3', 
        text1: 'services.10',
        text2: 'services.11',
        text3: 'services.12',
        text4: 'services.13',
        text5: 'services.14',
        text6: 'services.15',
    },
    {
        title: 'services.4', 
        text1: 'services.16',
        text2: 'services.17',
        text3: 'services.18',
        text4: 'services.19',
        text5: 'services.20',
        text6: 'services.21',
    },
]

function Cybersecurity() {
    
    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    return (
        <div className="overflow-hidden mt-[80px] xl:mt-[500px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between relative">
               <div className="flex flex-col gap-[15px]">
                    {data.map((dat, index) => (
                        <div key={index} className="flex gap-[30px] cursor-pointer" onClick={() => setActive(index)}>
                            <p className="text-white roboto italic font-[300] text-[25px]">0{index+1}</p>
                            <div className={`transition-all px-[25px] flex items-center justify-between w-[353px] h-[56px] rounded-[70px] ${active === index ? 'bg-[#34F3F3]' : 'bg-black border border-white'}`}>
                                <p className={`transition-all text-[14px] xl:text-[17px] ${active === index ? 'text-black font-[500]' : 'text-white font-[300]'}`}>{t(dat.title)}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect width="24" height="24" rx="12" fill={active === index ? 'black' : '#34F3F3'}/>
                                    <path d="M5 12H19" stroke={active === index ? 'white' : 'black'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 5L19 12L12 19" stroke={active === index ? 'white' : 'black'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    ))}
               </div>
               <div className="flex flex-col justify-between mt-[40px] xl:mt-0">
                    <div>
                        <p className="xl:text-[30px] text-[25px] leading-[1.4] text-[#39ABFE] pepsi">{t('services.3')}</p>
                    </div>
                    <p className="max-w-[525px] text-white font-[300] text-[14px] xl:text-[17px] mt-[20px] xl:mt-0">{t(data[active].text)}</p>
                    <div className="buttonNew flex mr-auto cursor-pointer mt-[20px] xl:mt-0">
                        <p className="open-sans text-[17px] font-[700] leading-[1.4]">{t('main.5')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <rect x="0.731445" y="0.530151" width="20.9397" height="20.9397" rx="10.4698" fill="black"/>
                            <path d="M5.09375 11H17.3086" stroke="white" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.2012 4.8927L17.3086 11.0001L11.2012 17.1075" stroke="white" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
               </div>
            </div>
        </div>
    );
}

export default Cybersecurity;

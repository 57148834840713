import React, { useEffect } from "react";

import Main from '../components/Services/Main'
import Cybersecurity from "../components/Services/Cybersecurity";

function Services () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Cybersecurity/>
        </div>
    );
}

export default Services;

import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import our1 from '../../img/Home/our1.png'
import our2 from '../../img/Home/our2.png'
import our3 from '../../img/Home/our3.png'
import our4 from '../../img/Home/our4.png'
import our5 from '../../img/Home/our5.png'


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

import Fade from "react-reveal/Fade";

const dataSwiperMob = [
    {
        title: 'main.12',
        text1: 'main.13',
        img: our1,
    },
    {
        title: 'main.14',
        text1: 'main.15',
        img: our2,
    },
    {
        title: 'main.16',
        text1: 'main.17',
        img: our3,
    },
    {
        title: 'main.18',
        text1: 'main.19',
        img: our4,
    },
    {
        title: 'main.20',
        text1: 'main.21',
        img: our5,
    },
]

function OurAdvantages() {
    
    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    return (
        <div className="overflow-hidden mt-[80px] xl:mt-[200px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex-col-reverse xl:flex-row justify-between relative hidden xl:flex">
                <Fade left>
                    <div className="xl:w-[757px] xl:h-[293px] gradient-box bg-[#000000B3] flex p-[40px] justify-between">
                        <div className="flex flex-col justify-between">
                            <p className="text-[20px] xl:text-[40px] leading-[1.2] text-[#34F3F3] open-sans"><Trans>{t('main.12')}</Trans></p>
                            <p className="text-[#FFFFFFB2] open-sans text-[14px] xl:text-[17px]">{t('main.13')}</p>
                        </div>
                        <img src={our1} alt=""/>
                    </div>
                </Fade>
                <div className="flex items-end flex-col">
                    <p className="pepsi text-[#34F3F3] text-[45px] xl:text-[125px] leading-[1]">{t('main.10')}</p>
                    <p className="pepsi text-[#39ABFE] text-[25px] xl:text-[55px] leading-[1]">{t('main.11')}</p>
                </div>
            </div>
            <Fade bottom>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative mt-[20px] hidden xl:block">
                <div className="h-[255px] gradient-box bg-[#000000B3] flex p-[40px] justify-between">
                    <div className="flex flex-col justify-between">
                        <p className="text-[20px] xl:text-[40px] leading-[1.2] text-[#34F3F3] open-sans"><Trans>{t('main.14')}</Trans></p>
                        <p className="text-[#FFFFFFB2] open-sans text-[14px] xl:text-[17px]">{t('main.15')}</p>
                    </div>
                    <img src={our2} alt=""/>
                </div>
            </div>
            </Fade>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative mt-[20px] hidden xl:flex justify-between">
                <Fade left>
                <div className="w-[557px] h-[487px] gradient-box bg-[#000000B3] p-[40px] flex flex-col justify-between">
                    <p className="text-[20px] xl:text-[40px] leading-[1.2] text-[#34F3F3] open-sans"><Trans>{t('main.16')}</Trans></p>
                    <img src={our3} alt=""/>
                    <p className="text-[#FFFFFFB2] open-sans text-[14px] xl:text-[17px]">{t('main.17')}</p>
                </div>
                </Fade>
                <Fade right>
                <div className="w-[703px] h-[487px] gradient-box bg-[#000000B3] p-[40px] flex flex-col justify-between">
                    <p className="text-[20px] xl:text-[40px] leading-[1.2] text-[#34F3F3] open-sans"><Trans>{t('main.18')}</Trans></p>
                    <img src={our4} alt=""/>
                    <p className="text-[#FFFFFFB2] open-sans text-[14px] xl:text-[17px]">{t('main.19')}</p>
                </div>
                </Fade>
            </div>
            <Fade bottom>    
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative mt-[20px] hidden xl:block">
                <div className="h-[255px] gradient-box bg-[#000000B3] flex p-[40px] justify-between">
                    <div className="flex flex-col justify-between">
                        <p className="text-[20px] xl:text-[40px] leading-[1.2] text-[#34F3F3] open-sans"><Trans>{t('main.20')}</Trans></p>
                        <p className="text-[#FFFFFFB2] open-sans text-[14px] xl:text-[17px]">{t('main.21')}</p>
                    </div>
                    <img src={our5} alt=""/>
                </div>
            </div>  
            </Fade>  
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto block xl:hidden relative">
                <div className="flex items-end flex-col">
                    <p className="pepsi text-[#34F3F3] text-[45px] xl:text-[125px] leading-[1]">{t('main.10')}</p>
                    <p className="pepsi text-[#39ABFE] text-[25px] xl:text-[55px] leading-[1.5]">{t('main.11')}</p>
                </div>
                <Swiper
                    modules={[Autoplay]} // Add any Swiper modules you need
                    slidesPerView={1} // Default slides per view
                    breakpoints={{
                        1: { // Tablet
                        slidesPerView: 1,
                        },
                        768: { // Desktop or larger
                        slidesPerView: 2, // Change this if you need a different number for desktop
                        },
                    }}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    autoplay={{ delay: 7000 }} // Optional: auto-slide every 3 seconds
                    className="mt-[30px]"
                >
                    {dataSwiperMob.map((mob, index) => (
                        <SwiperSlide key={index}>
                            <div className="h-[300px] gradient-box bg-[#000000B3] p-[20px] flex flex-col justify-between">
                                <p className="text-[18px] leading-[1.2] text-[#34F3F3] font-[600]"><Trans>{t(mob.title)}</Trans></p>
                                <p className="text-[#FFFFFFB2] open-sans text-[14px] xl:text-[17px] mt-[10px]">{t(mob.text1)}</p>
                                <div className="h-[144px] gradient-box overflow-hidden !rounded-[10px]">
                                    <img src={mob.img} alt="" className="object-cover h-full w-full"/>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className='flex mt-[20px] justify-between'>
                    <div onClick={() => swiper.slidePrev()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect x="35" y="35.4532" width="35" height="35" rx="17.5" transform="rotate(-180 35 35.4532)" fill="#34F3F3"/>
                            <path d="M27.7085 17.9532L7.29183 17.9532" stroke="black" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 28.1615L7.29167 17.9532L17.5 7.74483" stroke="black" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div onClick={() => swiper.slideNext()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect y="0.453247" width="35" height="35" rx="17.5" fill="#34F3F3"/>
                            <path d="M7.2915 17.9533H27.7082" stroke="black" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.745L27.7083 17.9533L17.5 28.1617" stroke="black" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurAdvantages;

import React from "react";

import { useTranslation } from "react-i18next";

import main from '../../img/Services/main.png'

import Fade from "react-reveal/Fade";

function Main() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden">
            <svg className="hidden xl:block absolute top-0 right-0" xmlns="http://www.w3.org/2000/svg" width="682" height="745" viewBox="0 0 682 745" fill="none">
                <g filter="url(#filter0_f_12_8917)">
                    <circle cx="392.129" cy="353.129" r="110.5" transform="rotate(137.442 392.129 353.129)" fill="#25FFE5"/>
                </g>
                <defs>
                    <filter id="filter0_f_12_8917" x="0.62793" y="-38.3719" width="783.001" height="783.001" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="140.5" result="effect1_foregroundBlur_12_8917"/>
                    </filter>
                </defs>
            </svg>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col relative">
                <div className="mx-auto xl:mx-0 xl:ml-auto flex flex-col mt-[100px] xl:mt-[140px]">
                    <div className="flex items-end gap-[15px]">
                        <p className="pepsi text-[112px] xl:text-[200px] text-[#34F3F3] leading-[1.4]">{t('main.1')}</p>
                        <p className="pepsi text-[42px] xl:text-[75px] text-[#39ABFE] leading-[2.3]">{t('main.2')}</p>
                    </div>
                    <p className="roboto text-white mx-auto xl:mx-0 xl:ml-auto font-[300] text-[14px] xl:text-[17px] max-w-[525px]">{t('services.1')}</p>
                    <div className="buttonNew mt-[20px] xl:mt-[50px] flex mx-auto xl:mx-0 xl:ml-auto cursor-pointer">
                        <p className="open-sans text-[17px] font-[700] leading-[1.4]">{t('main.5')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <rect x="0.731445" y="0.530151" width="20.9397" height="20.9397" rx="10.4698" fill="black"/>
                            <path d="M5.09375 11H17.3086" stroke="white" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.2012 4.8927L17.3086 11.0001L11.2012 17.1075" stroke="white" stroke-width="1.74497" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>               
            </div>
            <Fade left bottom duration={2000}>
                <img src={main} alt="" className="xl:absolute left-0 top-[140px] mt-[40px] xl:mt-0"/>
            </Fade>
        </div>
    );
}

export default Main;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import logo_mob from "../img/logo.png";
import MobileMenu from "./MobileMenu";

function Header() {
    const { t } = useTranslation();
    const location = useLocation();
    
    const [isProductsOpen, setIsProductsOpen] = useState(false);

    const toggleProductsMenu = () => {
        setIsProductsOpen(!isProductsOpen);
    };

    const closeProductsMenu = () => {
        setIsProductsOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsProductsOpen(false);
        };
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'cz', label: 'CZ' },
        { value: 'pl', label: 'PL' },
        { value: 'ar', label: 'AR' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
        document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="header-container">
            <div className="hidden max-w-[1280px] h-[60px] justify-between mx-auto xl:flex items-center headerbg p-[20px]">
               <div>
                <Link to="/"><img src={logo} alt=""/></Link>
               </div>
               <div className="flex items-center gap-[40px]">       
                <Link to="/" onClick={closeProductsMenu}><p className={`text-[16px] open-sans ${location.pathname === '/' ? 'text-black' : 'text-black'}`}>{t('header.1')}</p></Link>
                <Link to="/" onClick={closeProductsMenu}><p className={`text-[16px] open-sans ${location.pathname === '/' ? 'text-black' : 'text-black'}`}>{t('header.2')}</p></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                />
                <a href="/">
                    <div className="button cursor-pointer">
                        <p className="open-sans text-black text-[16px]">{t('header.3')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" rx="12" fill="black"/>
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </a>
               </div>
            </div>
            <div className="xl:hidden flex items-center justify-between headerbg h-[60px] p-[20px]">
                <Link to="/"><img src={logo_mob} alt="" /></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    className="ml-auto mr-[20px]"
                />
                <MobileMenu/>
            </div>
        </div>
    );
}

export default Header;
